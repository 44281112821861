<template>
  <section class="pb-2">
    <b-card class="mb-2">
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary font-weight-bold"> Cerca Auto </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>

            <b-breadcrumb-item active> Cerca auto </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <b-row class="py-2">
        <b-col md="4" class="my-3">
          <div class="p-3">
            <b-img src="@/assets/images/multipubblicazione.svg" />

            <p class="text-primary-light font-weight-bold mt-2">
              Configura i tuoi canali
            </p>

            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              esiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
              ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
              accumsan lacus vel facilisis.
            </p>

            <p class="text-primary-light">
              Puoi gestire le impostazioni dei canali che colleghi da quest'area
            </p>
          </div>
        </b-col>

        <b-col md="4" class="my-3">
          <b-card class="h-100">
            <template #default>
              <div
                class="d-flex align-items-center justify-content-center h-25 mb-2"
              >
                <b-img src="@/assets/images/logo-gestionale-auto.svg" />
              </div>

              <div class="mt-3">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do esiusmod tempor incididunt ut labore et dolore magna
                  aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo
                </p>
              </div>
            </template>

            <template #footer>
              <b-button block variant="primary">Attiva</b-button>

              <!-- <feather-icon size="25" icon="SettingsIcon" class="ml-4 cursor-pointer" v-b-modal.modal-channel></feather-icon> -->
            </template>
          </b-card>
        </b-col>

        <b-col md="4" class="my-3">
          <b-card class="h-100">
            <div
              class="d-flex align-items-center justify-content-center h-25 mb-2"
            >
              <b-img src="@/assets/images/logo-gestionale-motori.svg" />
            </div>

            <div class="mt-3">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                esiusmod tempor incididunt ut labore et dolore magna aliqua.
                Quis ipsum suspendisse ultrices gravida. Risus commodo
              </p>
            </div>

            <template #footer>
              <b-button variant="primary" block>Attiva</b-button>

              <!-- <feather-icon size="25" icon="SettingsIcon" class="ml-4 cursor-pointer"></feather-icon> -->
            </template>
          </b-card>
        </b-col>

        <b-col md="4" class="my-3">
          <b-card class="h-100">
            <div
              class="d-flex align-items-center justify-content-center h-25 mb-2"
            >
              <b-img src="@/assets/images/logo-autoscout.svg" />
            </div>

            <div class="mt-3">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                esiusmod tempor incididunt ut labore et dolore magna aliqua.
                Quis ipsum suspendisse ultrices gravida. Risus commodo
              </p>
            </div>

            <template #footer>
              <b-button variant="primary" block>Attiva</b-button>

              <!-- <feather-icon size="25" icon="SettingsIcon" class="ml-4 cursor-pointer"></feather-icon> -->
            </template>
          </b-card>
        </b-col>

        <b-col md="4" class="my-3">
          <b-card class="h-100">
            <div
              class="d-flex align-items-center justify-content-center h-25 mb-2"
            >
              <b-img src="@/assets/images/logo-window.svg" />
            </div>

            <div class="mt-3">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                esiusmod tempor incididunt ut labore et dolore magna aliqua.
                Quis ipsum suspendisse ultrices gravida. Risus commodo
              </p>
            </div>

            <template #footer>
              <div class="d-flex">
                <b-button variant="outline-primary" class="w-50"
                  >Disattiva</b-button
                >

                <b-button
                  variant="outline-primary"
                  class="d-flex align-items-center w-50 justify-content-center ml-2"
                  v-b-modal.modal-channel
                >
                  <feather-icon
                    icon="SettingsIcon"
                    class="mr-1 cursor-pointer"
                  ></feather-icon>
                  <span>Impostazioni</span>
                </b-button>
              </div>
            </template>
          </b-card>
        </b-col>

        <b-col md="4" class="my-3">
          <b-card class="h-100">
            <div
              class="d-flex align-items-center justify-content-center h-25 mb-2"
            >
              <b-img src="@/assets/images/logo-motork.svg" />
            </div>

            <div class="mt-3">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                esiusmod tempor incididunt ut labore et dolore magna aliqua.
                Quis ipsum suspendisse ultrices gravida. Risus commodo
              </p>
            </div>

            <template #footer>
              <b-button variant="primary" block>Attiva</b-button>

              <!-- <feather-icon size="25" icon="SettingsIcon" class="ml-4 cursor-pointer"></feather-icon> -->
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-card>

    <!-- Modali -->

    <b-modal
      id="modal-channel"
      centered
      title="Configurazione Canale di Pubblicazione"
      cancel-title="Chiudi"
      cancel-variant="outline-primary"
    >
      <div class="text-center py-2">
        <b-img src="@/assets/images/logo-gestionale-auto.svg" />
      </div>

      <div class="my-3">
        <validation-observer ref="recoveryMailValidation">
          <b-form-group label="E-mail">
            <validation-provider
              #default="{ errors }"
              name="E-mail"
              rules="email|required"
            >
              <b-form-input
                type="email"
                placeholder="Inserisci la tua e-mail per questo canale"
              >
              </b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Password">
            <validation-provider
              #default="{ errors }"
              name="Password"
              rules="required"
            >
              <b-form-input
                type="password"
                placeholder="Inserisci la tua password per questo canale"
              >
              </b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Numero auto pubblicabili">
            <validation-provider
              #default="{ errors }"
              name="Numero Auto"
              rules="required"
            >
              <b-form-input
                type="number"
                placeholder="Specifica il numero di auto pubblicabili"
              >
              </b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </div>

      <template #modal-footer>
        <div class="d-flex flex-grow-1 align-items-center">
          <div class="d-flex flex-grow-1 align-items-center">
            <div class="cursor-pointer">
              <feather-icon
                size="20"
                icon="AlertOctagonIcon"
                class="text-primary-light mr-1"
              ></feather-icon>
              <span class="text-primary-light">Istruzioni</span>
            </div>
          </div>

          <div>
            <b-button
              variant="outline-primary"
              @click="$bvModal.hide('modal-channel')"
              >Chiudi</b-button
            >
            <b-button variant="primary" class="ml-2">Salva</b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
import {
  BCol,
  BRow,
  BBreadcrumb,
  BBreadcrumbItem,
  BButton,
  BCard,
  BImg,
  BModal,
  BFormInput,
  BFormGroup,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider, localize } from "vee-validate";

export default {
  components: {
    BCol,
    BRow,
    BBreadcrumb,
    BBreadcrumbItem,
    BButton,
    BCard,
    BImg,
    BModal,
    BFormInput,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
  },

  created() {
    localize("it");
  },
};
</script>
